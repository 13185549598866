import React from "react"
import Img from "gatsby-image/withIEPolyfill"

export default ({desktopImg, mobileImg, smallImg, options}) => {
	if (!smallImg) smallImg = mobileImg;
	return (
		<Img
			{ ...options }
			fluid = {
				[
					smallImg.childImageSharp.fluid,
					{
						...mobileImg.childImageSharp.fluid,
						media: `(min-width: 481px)`,
					},
					{
					  ...desktopImg.childImageSharp.fluid,
					  media: `(min-width: 769px)`,
					},
				]
			}/>
	)
}